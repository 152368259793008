.profilePage{
    // main[role=main]{
    //     margin-top: 89px;
    // }
    .title_box{
        text-align: left;
        border-left:4px solid  $blue_u;
        padding-left: pxToEm(19);
        line-height: initial;
        margin-bottom: pxToEm(44);
        h1{
            line-height: pxToEm(29);
            margin-bottom: 0.85rem;
        }
    }
}
.profile_box{
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: pxToEm(91);
}
.pro_img{
    position: absolute;
    left: 0;
    width: 26%;
    max-width: 305px;
    @include box-shadow(12px 12px 0 $blue_u);
    z-index: 1;
    img{
        width: 100%;
        max-width: 100%;
        height: auto !important;
    }
}
.pro_content{
    position: relative;
    top: 16px;
    width: 78%;
    max-width: 936px;
    border: solid 2px #a0a0a0;
    z-index: 0;
    padding: pxToEm(36) pxToEm(27) pxToEm(34) pxToEm(103);
}

@include media-breakpoint-down(sm){
    .pro_img{
        width: 32%;
    }
}

@include media-breakpoint-down(xs){
    .profilePage .title_box{
        margin-bottom: 0.6rem;
        padding-left: 1.02rem;
        h1{
            margin-bottom: .45rem;
        }
    } 

    .profile_box{
        width: 100%;
        display: block;
        margin-bottom: pxToEm(60);
    }
    .pro_content{
        width: 100%;
        padding: pxToEm(80) pxToEm(16) pxToEm(34) pxToEm(16);
    }
    .pro_img{
        position: relative;
        width: 70%;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
}