/*----------------------header------------------------*/

.header_show {
    transition: .4S;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(#131314,0);
    z-index: 10;
    li {
        list-style: none;
    }
    &.active{
        background: rgba(#131314,1);
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 79px;
    padding: 0 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: pxToEm(14);
    // background: url(../images/bavv.jpg) top left no-repeat;
}



.logo {
    width: 220px;
    height: 39px;
    background: url(~chihui_logo.svg) top left no-repeat;
    background-size: contain;
    text-indent: -10000px;
    a {
        @include aLink();
    }
}


/*----------------------nav------------------------*/
.product_bottom i {
    display: none;
}
.nav_box {
    position: relative;
  
    ul {
        display: flex;
        li{
            position: relative;
            text-align: center;
            width: 80px;
        }
        li:not(:last-child){
            margin-right: 1.5em;
        }
    }
    .tw,.en,a:after{
        display: block;
        width: 100%;
        position: absolute;
        transition: .2s;
    }
    .tw{
        opacity: 0;
    }
    .en{
        opacity: 1;
    }

    a{
        width: 100%;
        display: block;
        color: $white;
        &:after{
            transition: .4s;
            position: relative;
            content:'';
            width: 0;
            height: 2px;
            background: $blue;
            bottom: -26px;
            left: 8px;
            opacity: 0;
        }
        &:hover{
            &:after{
                width: 77%;
                opacity: 1;
            }
            .en{
                opacity: 0;
            }
            .tw{
                opacity: 1;
            }
        }
    }
}


/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 31px;
$bar-height: 2px;
$bar-spacing: 10px;
$bar-color:#fff;
.menu-wrapper {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    width: $bar-width;
    height: $bar-height;
    @include border();
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: $bar-color;
    transition: all 0ms 300ms;
    @include border(99px);
    &.animate {
        background: rgba(255, 255, 255, 0);
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $bar-color;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $bar-color;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

.hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}




@include media-breakpoint-down(md){
    $header_heright: 70px;

    .logo{
        width: 192px;
        height: 34px;
    }
    .header_show{
        background: $black;
    }
    .header_box {
        width: 90%;
        height:  $header_heright;
        font-size: pxToEm(17);
        margin: 0 auto;
        padding: 0;
    }
    .nav_box nav{
        width: 90%;
        margin: auto;
    }
    .nav_box {
        display: none;
        position: fixed;
        top:  $header_heright;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background: $black;
         .en,.tw, a:after{
             position: relative;
         }
         .en{
             opacity: 1 !important;
         }
         .tw{
            display: none;
         }
        ul {
            display: block;
            li {
                width: 100%;
                border-bottom: 1px solid #313131;
                a {
                    padding: 40px 15px;
                    text-align: left;
                    text-decoration: none;
                }
            }
        }
        ul li a:hover{
            color: $white;
        }
        ul li a:after {
            display: none;
        }
        ul>li:not(:last-child) {
            margin-right: 0;
        }

    }
    .menu-wrapper {
        display: block;
    }

}


