.indexPage{
    main[role="main"]{
        margin: 0;
        padding: 0;
    }
}
//item1
.index_item1{
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    background:url(~images/in_img_bg.png) no-repeat top left / cover;
    .in_box{
        padding-left: 4%;
       height: 100%;
       display: flex;
       justify-content: space-between;
       align-items: center;
       position: relative;
    }
    .video_line{
        top: initial;
        bottom: 0;
        z-index: 0;
    }
    .sroll_btn{
        position: absolute;
        left: 4%;
        bottom: 20px;
        text-align: center;
        width: 16px;
        height: 129px;
        z-index: 1;
        span{
            font-size: pxToEm(14);
            color: #ffffff;
            -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr ;
        }
        &:after{
            display: block;
            content: "";
            width: 2px;
            height: 0;
            background-color: #fff;
            margin: 3px auto 0 auto;
            position: relative;
            left: -2px;
            animation: scroll_line 1s linear infinite;
        }
        @keyframes scroll_line {
            0%{
                height: 0;
            }
            100%{
                height: 97px;
            }
        }
    }
}
.in_item_txt{
    h2{
        font-family: "FuturaLT";
        font-size: pxToEm(115);
        font-size: 12.25vmin;
        line-height: 1.12;
        letter-spacing: 5.8px;
        color: $blue;        
    }
    h3{
        font-size: pxToEm(35);
        font-size: 3.75vmin;
        font-weight: 300;
        line-height: 1.76;
        letter-spacing: 3.5px;
    }
}
.in_item_img{
    padding: 6% 0 4%;
    width: 64%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    z-index: 1;
    .item_photo{
        &:nth-child(1){
            width: 47.897%;
            max-width: 560px;
            margin-right: pxToEm(26);
            margin-bottom: pxToEm(29.6);
        }
        &:nth-child(2){
            width: 45.502%;
            max-width: 532px;
            margin-bottom: pxToEm(29.6);
        }
        &:nth-child(3){
            width: 47.897%;
            max-width: 560px;
            margin-right: pxToEm(26);
        }
        &:nth-child(4){
            width: 35.666%;
            max-width: 417px;
        }
    }
    img{
        display: block;
        width: 100%;
    }
}

//item2

.index_item2{
   padding-top: pxToEm(55);
    display: flex;
    flex-wrap: wrap;
   a{
       position: relative;
       width: 100%;
       height: 100%;
       display: block;
       &:after,&:before{
            position: absolute;
            display: block;
            width: 100%;
        }
        &:before{
            transition: .4s;
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            background: rgba($black,0.4);
            z-index: 1;
        }
        &:after{
            content: '';
            height: 0;
            padding-bottom: 26%;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg,rgba($black, 0.89) 34%,rgba($white, 0));
        } 
        &:hover{
            a{text-decoration: none;}
            &:before{
                opacity: 0;
            }
        }
   }
   .title_box a{
    width: auto;
    height: auto;
    &:before,&:after{
        display: none;
    }
}
  .in_work_first_img,.in_work_item_img{
      display: flex;
      position: relative;
      width: 50%;
      .word_txt{
        width: 93%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        h2{
            font-size: pxToEm(18);
            @include clamp(1.5,2);
            color: $white;
            margin-bottom: pxToEm(18);
        }
    }
  }  
  .in_work_first_img .word_photo{
      width: 100%;
      height: 0;
      padding-bottom: 84.709%;
      width: 100%;
  }
  .in_work_item_img{
        flex-wrap: wrap;
        .in_work_item_box{
            width: 50%;
        }
        .word_photo{
            width: 100%;
            height: 100%;
        }
  }
}

//item3
.index_item3 .title_box,.index_item2 .title_box{
    width: 60%;
    position: relative;
    margin: 0 auto 7.5rem;
    z-index: 2;
    a{
        transition: .4s;
        position: absolute;
        color: $white;
        font-size: pxToEm(14.5);
        display: inline-block;
        right: 0;
        bottom: 0px;
        i{
          transition: .4s;
           position: absolute;
           right: -46px;
           bottom: 5px;
            width: 39px;
            height: 1px;
            background: $white;
            &:after{
                transition: .4s;
                position: absolute;
                left: 31px;
                top: -3px;
                content: "";
                display: inline-block;
                width: 10px;
                height: 1px;
                background: $white;
                transform: rotate(42deg);
            }
        }
        &:hover{
            color: $blue;
            text-decoration: none;
            i{
                background: $blue;
                right: -76px;
                width: 68px;
                &:after{
                    left: 60px;
                    background: $blue;
                }                
            }
        }
    }
}
$slider:410px;
.article_list{
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    .sliderfor{
        width:100%;
        min-height: $slider;
        margin: 0 auto 100px;
        position: relative;
    }
    li{
       // transition:0.5s;
        list-style: none;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
        a{
            display: flex;
            align-items: center;
            position: relative;
            width: $slider;
            height: $slider;
            border-radius: 99em;
            overflow: hidden;
            position: relative;
            transform: scale(0.71);
            margin: auto;
            cursor: auto;
            h2{
                transform: scale(1.3);
            }
            &:before{
                position: absolute;
                display: block;
                content: '';
                transition-property: all;
                transition-duration: 0.6s;
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
                width: 80%;
                height: 80%;
                background: rgba(0,0,0,.85);
                @include border(99em);
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 1;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }

    .article_img{
        width: 100%;
    }
    .article_photo{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .article_content{
        transition: .4s;
        width: 58%;
        margin: auto;
        position: relative;
        z-index: 1;
        color: $white;
        h2{
            transition: .35s;
            transition-timing-function: ease;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: pxToEm(18);
            @include clamp(1.5,2);
            position: relative;
            top: 0;
            width: 60%;
            margin: auto;
        }
        p{
            font-size: pxToEm(15);
            @include clamp(1.5,5);
            display: block;
            width: 300px;
            position: absolute;
            top: 56%;
            left: 50%;
            opacity: 0;
            transform: translate(-50%,-50%);
            
        }
        .view_btn{
            margin-top: 50px;
            text-align: center;
            color: $blue;
            font-size: pxToEm(15);
            position: absolute;
            left: 50%;
            bottom: -110px;
            transform: translateX(-50%);
            opacity: 0;
            &:after{
                display: block;
                content:'';
                width: 35%;
                height: 1px;
                background: $blue;
                margin: 5px auto 0 auto;
            }
        }
    }
    //active 的內容
    .slick-current.slick-center{
        &:after{
            display: none;
        }
        a{
            @include tr_delay(0.8s);
            transform: scale(1); 
            cursor: pointer;
            p{
                transition-duration: 0s;
            }
            &:hover{
                text-decoration: none;
                -webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==');
                &:before{
                    width: 100%;
                    height: 100%;
                }
                h2{
                    top: -88px;
                }
                p{
                    transition-duration: 0.4s;
                    transition-timing-function: ease;
                    transition-delay:.2s;
                    opacity: 1;
                }
                .article_content{
                    width: 80%;
                    .view_btn{
                       opacity: 1;
                    }
                }
                .article_photo{
                    animation-play-state:paused;
                }
            }

        }
        .article_photo{
            animation-play-state:running;
            animation:spin 13.3s linear .8s infinite;
        }
        @keyframes spin { 
            100% { 
                -webkit-transform: rotate(360deg); 
                transform:rotate(360deg); 
            } 
        }
    }
    .slick-dots li{
        width: auto;
        margin: auto;
    }

    //list
    .slider-nav{
        display: inline-block !important;
        li{
            text-indent: -10000px;
            width: auto!important;
            height: auto;
            float: none;
            display: inline-block;
            margin: auto;
        }
        .slick-dots{
            position: relative;
            margin: 0;
        }
      
        .slick-track{
            width: auto !important;
        }
        //左右按鈕
        .slick-dots li button:before{
            background: #333333;
        }
        .slick-dots li.slick-active button:before{
            background:$white;
        }
        .slick-next, .slick-prev{
            top: 64%;
            width: 32px;
            height: 32px;
            transition: 0.4s;
            @include border(99em);
            background: #2b2c2e;
            font-size: initial;
            outline: 0;
        }
        .slick-prev{
            left: -54px;
            &:before{
                position: absolute;
                top: 28%;
                left: 50%;
                transform: translate(-50%);
                @extend %list_a;
                @include triangle_specially(left_l, 7px ,7px ,$white);
            }
            &:hover{
                background: #2f3a88;
            }
        }
        .slick-next{
            right: -45px;
            &:before{
                position: absolute;
                top: 28%;
                left: 54%;
                transform: translate(-50%);
                @extend %list_a;
                @include triangle_specially(right_r, 7px ,7px ,$white);
               
            }
            &:hover{
                background: #2f3a88;
            }            
        }
    }
}


//index_item3
.index_item3{
    padding: 85px 0 78px 0;
    min-height: 739px;
}

//index_item4
.index_item4{
    padding: 56px 0 70px;
    background: url(../images/in_contact_bg.png?73f8f8a2) no-repeat top / cover;
    article{
        background: none;
    }
    .form_box{
        padding: 0;
        width: 100%;
    }
    .title_box{
        margin-bottom: pxToEm(20);
    }
}
@include screen(1366px) {
    .article_list li a{
        width: 390px;
        height: 390px;
        transform: scale(0.69);
        @include screen(1024px) {
            width: 370px;
            height: 370px;
            transform: scale(0.6);
        }
    }
}
@include screen(1024px) {
    .index_item3{
        padding: 40px 0 50px; 
        min-height: auto;
    }
    .index_item3 .title_box, .index_item2 .title_box{
        margin-bottom: 3.5rem;
    }
    .article_list .sliderfor{
        margin: 0 auto 20px;
    }
    .in_item_txt h2{
        font-size: 10vmin;
    }
    .in_item_img{
        width: 58%;
        padding: 0;
    }
}

@include media-breakpoint-down(sm) {

    .article_list .slick-current.slick-center a:hover{
        &:before{
            width: 80%;
            height: 80%;
        }
        h2{
            top: 0;
        }
    }

    .index_item1{
        .in_box{
            flex-wrap: wrap;
        }
    } 
    .in_item_txt{
        width: 81%;
        margin: 0 auto 40px;
    }
    .in_item_img,.index_item2 .in_work_first_img, .index_item2 .in_work_item_img{
        width: 100%;
    }
    .index_item2{
        padding-top: pxToEm(40);
        width: 90%;
        margin: auto;
    }
    .index_item2 .in_work_item_img .word_photo{
        width: 100%;
        height: 0;
        padding-bottom: 85%;
    }
    .index_item4 .form_box{
        width: 100%;
    }
}


@include media-breakpoint-down(xs) {
    .index_item1 .sroll_btn{
        bottom: -24px;
    }
    @keyframes scroll_line {
        0%{
            height: 0;
        }
        100%{
            height: 67px;
        }
    }
    .index_item1{
        min-height: auto !important;
        padding: 10% 0 13% 0;
        .video_line{
            display: none;
        }
        .in_box{
            padding-left: 3%;
        }
    }


    .in_item_txt{
        h2{
            font-size: 15vmin;
        }
        h3{
            font-size: 5.75vmin;
        }
    } 

    .index_item2 .in_work_first_img .word_txt h2, .index_item2 .in_work_item_img .word_txt h2{
        font-size: pxToEm(15);
    }
    .indexPage main[role=main]{
        padding-top: 70px;
    }
    .in_item_img{
        .item_photo:first-child{
            margin-right: 0.7rem;
            margin-bottom: 0.7rem;
        }
        .item_photo:nth-child(2){
            margin-bottom: 0.7rem;
        }
        .item_photo:nth-child(3){
            margin-right: 0.7rem;
        }
    } 

    .article_list{
        li{
            width: 270px;
            height: 270px;
            @include screen(375px){
                width:260px;
                height:260px;
            }
            @include screen(360px){
                width:250px;
                height:250px;
            }
            a{
                width: 100%;
                height: 100%;
                transform: scale(.65);
            }
        }
        h2{
            transform: scale(1) !important;
            width: 85% !important;
            font-size: pxToEm(18);
        }
        .sliderfor{
            left: 0;
        }
        .sliderfor{
            min-height: auto;
            margin-bottom: 38px;
        }
        .article_content{
            width: 73%;
        }

        .slick-current.slick-center a{
            p,.view_btn{
                display: none;
            }
        } 
    } 


    .index_item2 .title_box, .index_item3 .title_box{
        width: 88%;
        margin-bottom: 2.5rem;
        a{
            bottom: 26px;
            font-size: pxToEm(12);
            i{
                position: relative;
                display: block;
                right: -42px;
                bottom: -9px;
                width: 21px;
                &:after{
                    left: 16px;
                    top: -2px;
                    width: 7px;
                }
            }
            &:hover{
                color: $white;
                i{
                    background:  $white;
                    position: relative;
                    display: block;
                    right: -42px;
                    bottom: -9px;
                    width: 21px;
                    &:after{
                        left: 16px;
                        top: -2px;
                        width: 7px;
                    }
                }
            }
        }
    }
}
