.contactPage {
    article{
        width: 100%;
        max-width: 100%;
        background: url(~images/contact_bg.png) no-repeat top center / cover;
    }
    main[role=main]{
        padding-bottom: 0;
    }
}

.contact_txt{
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: pxToEm(14);
    line-height: 1.5;
    letter-spacing: 0.7px;
    margin-bottom: 42px;
}
.form_box {
    padding: 0 0 50px 0;
    margin: auto;
    width: 82%;
    max-width: 1200px;
    font-size: pxToEm(17);
    .in_name h4{
        padding-left: 13px;
    }
    h4{
        letter-spacing: 1.7px;
        margin-right: 12px;
        position: relative;
        top: 20px;
        width: 93px;
        b{
            font-size: pxToEm(17);
            display: inline-block;
            margin-bottom: 0;
            margin-right: 3px;
        }
    }
    b,.with-errors{
        display: block;
        font-size: pxToEm(13);
        color: #9b0107;
        margin-bottom: pxToEm(20);
    }
    .with-errors{
        margin-top: 10px;
        margin-bottom: 0;
    }
    li{
        list-style: none;
    }
    .form-row{
        justify-content: space-between;
    }
    .col-lg-6{
        max-width: 570px;
    }
    .col-12{
        display: flex;
        min-height: 80px;
        margin-bottom: 20px;
        padding: 0;
        .form-group{
            @include calc(110px);
        }   
    }
    .form-control {
        border: none;
        background: #434343;
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;
    }
    .form-control:focus {
        box-shadow: 0 0 0 0.1rem rgba(#ff0000, 0);
    }
    .form_btn{
        text-align: center;
        margin-bottom: 0;
    }
    .form_btn button,.form_btn a{
        margin: auto;
        display: inline-block;
        color: $white;
        text-decoration: none;
        padding: 19.5px 49px;
        background-color: $blue;
        border: none;
        @include border(5px);
        &:hover {
            background: $blue;
            opacity: .5;
        } 
    }
    
    input[type='radio']{
        display: none;
    }
    .rad_box{
        min-height: auto;
        h4{
            top: 7px;
        }
    }
    .rad_box .rad_content{
        display: flex;
        align-items: center;
        .radio:nth-child(1){
            margin-right: 30px;
        }
    }
}

// 起始

input[type='radio'] + label{
    display: flex;
    align-items: center;
    width: 100%;
    height: 26px;
    padding: 0 5px 0 36px;
    outline: 0;
    transition: all 0.2s;
    cursor: pointer;
    position: relative;
    color: #ccc;

    &:before{
        height: 25px;
        width: 26px;
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 0;
        background: #434343;
        content: "";
        @include border();	 
    }
    span{
    	position: relative;
    	display: block;
    	top: 3px;
    	width: 100%;
    	
    }
}
input[type='radio']:checked + label{

}
//點下
input[type='radio']:checked + label:after {
    width: 10px;
    height: 10px;
    background: #fff;
    display: inline-block;
    position: absolute;
    top: 9px;
    left: 8px;
    content: "";
    @include border();	 
    @include screen(800px){
    	top: 9px;
    }
  
}
@include media-breakpoint-down(lg){
    .contactPage .contact_txt{
        margin-bottom: 30px;
    }
    .form_box{
        .col-lg-6{
            max-width: 100%;
        }
        .col-12{
            display: block;
            min-height: initial;
            .form-group{
                width: 100%;
            }
        }
        input{
            height: 40px;
        }
        h4{
            top: 0;
            margin-bottom: 1em;
        }
        .in_name h4{
            padding-left: 0;
        }
        .rad_box h4{
            top: 0;
        }
       .form_btn{
            margin-top: 20px;
            button{
                padding: 16.5px 40px;
           }
       } 
       .form-group{
            margin-bottom: 1.5rem;
       }
    } 
}

@include media-breakpoint-down(xs) {
    .contact_txt{
        font-size: pxToEm(15);
    }
    .form_box{
        font-size: pxToEm(16);
    }
}