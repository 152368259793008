
.wordPage{
   .container-fluid{
       margin-bottom: pxToEm(66);
   }
    .col-lg-4{
        padding: 0;
    }
} 


.tag_content{
    font-size: pxToEm(18);
    letter-spacing: 1.8px;
    color: $white;
    margin-bottom: pxToEm(33.8);
}

.word_img{
    position: relative;
    width: 100%;
    min-width: 400px;
    height: 0;
    padding-bottom: 85.004%;
    @include screen(1436px){
        min-width: 100%;
    }
    &:hover{
        a{text-decoration: none;}
        &:before{
            opacity: 0;
        }
        .word_photo{
            opacity: 1;
        }
    }
    .word_photo,&:after,&:before{
        position: absolute;
        display: block;
        width: 100%;
    }
    .word_photo{
        transition: .4s;
        top: 0;
        left: 0;
        height: 100%;
        opacity: .6;
    }
    .word_txt{
        width: 80%;
        position: absolute;
        bottom: pxToEm(17);
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        h2{
            font-size: pxToEm(18);
            @include clamp(1.5,2);
            color: $white;
            margin-bottom: pxToEm(18);
        }
    }
    &:after{
        content: '';
        height: 0;
        padding-bottom: 26%;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg,rgba($black, 0.89) 34%,rgba($white, 0));
    }
}
@include media-breakpoint-down(sm) {
    .word_img .word_txt{
        width: 92%;
    }
   
}
@include media-breakpoint-down(xs) {
    .tag_content{
        font-size: pxToEm(16);
        margin-bottom: pxToEm(20);
    }
    .word_img .word_txt{
        bottom: pxToEm(25);
    }
}