.newsPage{
    .title_box{
        h2{
            position: relative;
            &:before{
                width:100%;
                height: 1px;
                position: absolute;
                content: '';
                background: linear-gradient(270deg, #35383D , #FFFFFF  , #35383D ); 
                left: 0;
                top: -4px;
                animation: newslinerun 5s linear infinite;
                background-size: 300%;
            }
            @keyframes newslinerun {
                0%{
                    background-position: 300%;
                }

              
                100%{
                    background-position: 0%;
                }
            }
        }
    }
    .newsitem{
        padding: 0 ;
    }
    .word_img{
        padding-bottom: 51%;
        @media (max-width:575.99px) {
            padding-bottom: 52%;
        }
    }
    .word_txt{
        @media (max-width:575.99px) {
            bottom: 0;
        }
        h2{
            margin-bottom: 0;
        }
        div{
            margin-bottom: 10px;
        }
    }
}
.new-con{
    padding: 0 4%;
    position: relative;
    .word_img{
        padding-bottom: 51%;
        background: #000000;
    }
    .word_txt{
        h2{
        margin-bottom: 0;
        }
        div{
            margin-bottom: 10px;
            color: #FFFFFF;
        }
    }
    .news-l{
      padding-left: 20px;
      padding-right: 20px;
    }
    .news-r{
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 100px;
     @media (max-width:768px) {
        transform: translateX(21%);
        margin-top: 75px;
     }
     @media (max-width:557px) {
        margin-top: 40px;
     }
    }
}
.news-line{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 78vw;
    padding-bottom: 28%;


 

    @media (max-width:768px) {
        left: 49%;
        display: none;
    }
}
.nline-mo{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 82vw;
    height: 84vw;
    opacity: 0;
    @media (max-width:769px) {
        opacity: 1;
        display: block ;
    }
}

