.article_detailPage{
    .editor_Content header h2{
        width: 100%;
    }

}
.articlePage{
    main[role=main]{
        padding-top: 175px;
        margin-top: 0;
    }
    .word_img .word_photo{
        opacity: 1;
    }
    .word_img::before{
        content:'';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: rgba($black,0.85);
        width: 80%;
        height: 80%;
        z-index: 1;
    }
} 

.nav-morph_1,.nav-morph_2,.nav-morph_3,.nav-morph_4 {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  

  

.bg_line{
    position: absolute;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    display: flex;
    justify-content: space-between;
    .bg_line_box{
        position: relative;
        width: 25%;
        max-width: 279px;
        height: 100%;
        svg path {
            stroke: #35383d;
            stroke-width: 7px;
          }
        svg{
            position: absolute;
            content: '';
            display: block;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.articlePage{
    .container-fluid{
        padding: 0;
    }
    .col-lg-3{
        margin-bottom: 61.3px;
    }
    .word_img{
        min-width: 279px;
        min-height: 279px;
        padding-bottom: 100%;
        @include border(99em);
        overflow: hidden;
        -webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==');
        &:before{
            transition-property: all;
            transition-duration: 0.8s;
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            width: 80%;
            height: 80%;
            background: rgba(0,0,0,.85);
            @include border(99em);
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        &:after{
            display: none;
        }
       .word_txt{
            width: 51%;
            top: 50%;

            transform: translate(-50%,-50%);
            bottom: initial;
       }
       &:hover{
            .word_photo{
                opacity: .6;
            }
       }
        &:hover:before{
            opacity: 1;
            width: 120%;
            height: 150%;
        }
    }
}
@include media-breakpoint-down(xs) {
    .articlePage article{
        width: 75%;
    }
    .bg_line{
        .bg_line_box:first-child,.bg_line_box:last-child{
            display: none;
        }
        .bg_line_box{
            width: 50%;
        }
    }
    .articlePage .word_img:hover::before{
        width: 80%;
        height: 80%;
    }
    .articlePage main[role=main]{
        padding-top: 105px;
    }
    .word_img .word_txt h2{
        font-size: pxToEm(16);
    }
}