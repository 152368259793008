@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
@import url(https://fonts.googleapis.com/earlyaccess/cwtexfangsong.css?20190222);
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

@font-face {
  font-family: "FuturaLT";
  src: url(../assets/FuturaLT.ttf); }

@font-face {
  font-family: "DIN-Black";
  src: url(../assets/DINPro-Bold_13934.ttf); }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #2f3a88; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #ffffff;
  word-break: break-word;
  letter-spacing: 0.1em;
  font-family: "Noto Sans TC", "微軟正黑體", "FuturaLT", Verdana, sans-serif;
  z-index: 0;
  background: #131416;
  -webkit-text-size-adjust: 100%; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0 10px;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #666; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #666; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC", "微軟正黑體", "FuturaLT", Verdana, sans-serif;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 320px !important;
  overflow: hidden;
  z-index: 2; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 175px;
  padding-bottom: 55px;
  z-index: 1; }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
.title_box {
  width: 100%;
  margin: 0 auto 3.75rem auto;
  text-align: center;
  line-height: 1.5;
  color: #ffffff; }
  .title_box h1 {
    font-size: 2.125rem;
    font-family: "FuturaLT";
    color: #4759d9; }
  .title_box h2 {
    font-size: 1.25rem; }
  @media (max-width: 575.99px) {
    .title_box {
      margin-bottom: 2.5rem; }
      .title_box h1 {
        font-size: 1.75rem; }
      .title_box h2 {
        font-size: 1.0625rem; } }

.video_line {
  position: absolute;
  top: -7px;
  width: 100%;
  z-index: -1; }
  .video_line svg {
    width: 100%;
    height: 300px;
    display: block;
    margin: 0 auto; }

.curve {
  stroke-width: 1px;
  stroke-opacity: 0;
  stroke: #6673d2;
  fill: none;
  transform: translate(0, 90);
  fill-rule: evenodd; }
  .curve path {
    transform-origin: center 60px; }
  .curve path:nth-child(1) {
    animation: curve 6s linear 0.2s infinite; }
  .curve path:nth-child(2) {
    animation: curve 6s linear 0.4s infinite; }
  .curve path:nth-child(3) {
    animation: curve 6s linear 0.6s infinite; }
  .curve path:nth-child(4) {
    animation: curve 6s linear 0.8s infinite; }
  .curve path:nth-child(5) {
    animation: curve 6s linear 1s infinite; }
  .curve path:nth-child(6) {
    animation: curve 6s linear 1.2s infinite; }

@keyframes curve {
  0% {
    transform: none;
    stroke-opacity: .4; }
  50% {
    transform: rotateX(360deg) scaleY(0.8);
    stroke-opacity: .4; }
  100% {
    transform: none;
    stroke-opacity: .3; } }

/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 0; }
  .editor_Content header {
    width: 100%;
    padding-bottom: 31px;
    margin-bottom: 29px;
    border-bottom: solid 1px #7d7d7d;
    display: flex; }
    .editor_Content header h2 {
      width: 80%;
      font-size: 1.25rem;
      line-height: 1.4;
      color: #ffffff;
      padding-right: 2%; }
    .editor_Content header .tag_list {
      text-align: right;
      width: -moz-calc(100% - 73%);
      width: -webkit-calc(100% - 73%);
      width: calc(100% - 73%); }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.9375rem;
  color: #ffffff; }
  .editor_Box ul, .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: auto !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn, .more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a, .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .back_btn a:hover, .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .seesaw a {
    color: #fd7e14;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #fd7e14;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #fd7e14;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #fd7e14; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.top_btn {
  cursor: pointer;
  transition: .4s;
  opacity: 0;
  position: fixed;
  bottom: 3%;
  right: 43px;
  z-index: 8;
  font-size: 0.75rem;
  z-index: 9;
  width: 18px;
  height: 76px; }
  .top_btn a {
    display: block;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 16px; }
  .top_btn:hover .line {
    height: 67px;
    background: #4759d9; }
    .top_btn:hover .line:before {
      background: #4759d9; }
  .top_btn:hover a {
    color: #4759d9; }
  .top_btn span {
    position: absolute;
    bottom: 0;
    display: block;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(0deg); }
  .top_btn .line {
    transition: .4s;
    position: relative;
    width: 1px;
    height: 38px;
    background: #ffffff;
    display: block;
    left: -8px;
    bottom: 35px; }
    .top_btn .line:before {
      display: block;
      position: absolute;
      top: 4px;
      left: -1px;
      content: "";
      width: 10px;
      height: 1px;
      background: #ffffff;
      transform: rotate(-130deg); }
  .top_btn.active {
    transition: 0s;
    opacity: 1; }
  .top_btn.fix {
    position: absolute;
    transition: 0s;
    bottom: 112.2%; }
    @media screen and (max-width: 768px) {
      .top_btn.fix {
        bottom: 55.2%; } }

@-moz-document url-prefix() {
  .top_btn .line {
    left: 8px; } }

@media screen\0 {
  .top_btn a {
    position: static; }
  .top_btn .line {
    left: 6px;
    bottom: 0; }
  .top_btn:hover .line {
    height: 38px; } }

/*----------------------頁數------------------------*/
.page dt.ltbn a:before, .page dt.rtbn a:before, .article_list .slider-nav .slick-prev:before, .article_list .slider-nav .slick-next:before {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.page {
  font-size: 1.125rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto; }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block; }
    .page dt a,
    .page dd a {
      display: block;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      color: #ffffff; }
      .page dt a:hover,
      .page dd a:hover {
        color: #2f3a88; }
  .page dd {
    width: 2.3%; }
  .page dd.active a {
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    background: none;
    padding: 0;
    color: #4759d9;
    font-weight: bold; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: 32px;
    height: 32px;
    transition: 0.4s;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    background: #2b2c2e; }
  .page .nopage {
    opacity: 0.2; }
  .page dt.ltbn a {
    margin-right: 17px; }
    .page dt.ltbn a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 7px 7px 0;
      border-color: transparent #ffffff transparent transparent; }
    .page dt.ltbn a:hover {
      background: #2f3a88; }
  .page dt.rtbn a {
    margin-left: 17px; }
    .page dt.rtbn a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 0 7px 7px;
      border-color: transparent transparent transparent #ffffff; }
    .page dt.rtbn a:hover {
      background: #2f3a88; }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 20px; }
  right: 20px; }

@media (max-width: 1199.99px) {
  main[role="main"] {
    margin-top: 105px; } }

@media (max-width: 991.99px) {
  .editor_Content header {
    display: block;
    padding-bottom: 22px; }
    .editor_Content header h2 {
      width: 100%;
      padding-right: 0;
      padding-bottom: 24px; }
    .editor_Content header .tag_list {
      text-align: left;
      width: 100%; } }

@media (max-width: 575.99px) {
  .top_btn span {
    bottom: -10px; }
  .top_btn .line {
    height: 20px;
    bottom: 25px; }
    .top_btn .line::before {
      top: 3px;
      left: -1px;
      width: 8px; }
  .top_btn a:hover {
    color: #ffffff; }
    .top_btn a:hover .line {
      height: 20px;
      background: #ffffff; }
      .top_btn a:hover .line:before {
        background: #ffffff; }
  .video_line {
    left: 50%;
    transform: translateX(-50%);
    top: -24px;
    width: 350vw;
    opacity: 0.5; }
  .page {
    font-size: 1.0625rem; }
    .page dd {
      width: 11%; }
    .page dt.ltbn a {
      margin-right: 0.875rem; }
      .page dt.ltbn a:before {
        border-width: 5px 5px 5px 0; }
    .page dt.rtbn a {
      margin-left: 0.875rem; }
      .page dt.rtbn a:before {
        border-width: 5px 0 5px 5px; }
    .page dt.ltbn a, .page dt.rtbn a {
      width: 28px;
      height: 28px; }
  .back_btn, .more_btn {
    font-size: 1.0625rem; }
    .back_btn a, .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; }
  .tag_list a {
    font-size: 0.875rem; } }

.g-recaptcha {
  display: none; }

/*----------------------header------------------------*/
.header_show {
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(19, 19, 20, 0);
  z-index: 10; }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.header_box {
  position: relative;
  width: 100%;
  height: 79px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem; }

.logo {
  width: 220px;
  height: 39px;
  background: url(~chihui_logo.svg) top left no-repeat;
  background-size: contain;
  text-indent: -10000px; }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }

/*----------------------nav------------------------*/
.product_bottom i {
  display: none; }

.nav_box {
  position: relative; }
  .nav_box ul {
    display: flex; }
    .nav_box ul li {
      position: relative;
      text-align: center;
      width: 80px; }
    .nav_box ul li:not(:last-child) {
      margin-right: 1.5em; }
  .nav_box .tw, .nav_box .en, .nav_box a:after {
    display: block;
    width: 100%;
    position: absolute;
    transition: .2s; }
  .nav_box .tw {
    opacity: 0; }
  .nav_box .en {
    opacity: 1; }
  .nav_box a {
    width: 100%;
    display: block;
    color: #ffffff; }
    .nav_box a:after {
      transition: .4s;
      position: relative;
      content: '';
      width: 0;
      height: 2px;
      background: #4759d9;
      bottom: -26px;
      left: 8px;
      opacity: 0; }
    .nav_box a:hover:after {
      width: 77%;
      opacity: 1; }
    .nav_box a:hover .en {
      opacity: 0; }
    .nav_box a:hover .tw {
      opacity: 1; }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 31px;
  height: 22px;
  cursor: pointer;
  z-index: 10; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 31px;
  height: 2px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em; }

.hamburger-menu {
  position: relative;
  transform: translateY(10px);
  background: #fff;
  transition: all 0ms 300ms;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0); }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: #fff;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: #fff;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 991.99px) {
  .logo {
    width: 192px;
    height: 34px; }
  .header_show {
    background: #000; }
  .header_box {
    width: 90%;
    height: 70px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0; }
  .nav_box nav {
    width: 90%;
    margin: auto; }
  .nav_box {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #000; }
    .nav_box .en, .nav_box .tw, .nav_box a:after {
      position: relative; }
    .nav_box .en {
      opacity: 1 !important; }
    .nav_box .tw {
      display: none; }
    .nav_box ul {
      display: block; }
      .nav_box ul li {
        width: 100%;
        border-bottom: 1px solid #313131; }
        .nav_box ul li a {
          padding: 40px 15px;
          text-align: left;
          text-decoration: none; }
    .nav_box ul li a:hover {
      color: #ffffff; }
    .nav_box ul li a:after {
      display: none; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .menu-wrapper {
    display: block; } }

footer {
  width: 100%;
  position: relative;
  padding: 36px 80px 30px 80px;
  background: #000;
  color: #ffffff;
  line-height: 1.5; }
  footer a {
    color: #ffffff;
    text-decoration: none; }
    footer a:hover {
      color: #4759d9; }

.footer_box {
  position: relative;
  display: flex;
  align-items: flex-end; }

.f_email {
  font-size: 0.9375rem;
  letter-spacing: 0.8px;
  margin-left: 2.9rem; }

.copyright {
  position: absolute;
  right: 0;
  font-size: 0.8125rem;
  letter-spacing: 0.7px; }

@media (max-width: 1199.99px) {
  footer {
    padding: 30px 25px; }
  .footer_box {
    display: block; }
  .f_email {
    margin-left: 0;
    margin: 23px 0; }
  .copyright {
    position: relative; } }

/******************************************/
/*		pages
/******************************************/
.indexPage main[role="main"] {
  margin: 0;
  padding: 0; }

.index_item1 {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  background: url(~images/in_img_bg.png) no-repeat top left/cover; }
  .index_item1 .in_box {
    padding-left: 4%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
  .index_item1 .video_line {
    top: initial;
    bottom: 0;
    z-index: 0; }
  .index_item1 .sroll_btn {
    position: absolute;
    left: 4%;
    bottom: 20px;
    text-align: center;
    width: 16px;
    height: 129px;
    z-index: 1; }
    .index_item1 .sroll_btn span {
      font-size: 0.875rem;
      color: #ffffff;
      -webkit-writing-mode: vertical-lr;
      writing-mode: vertical-lr; }
    .index_item1 .sroll_btn:after {
      display: block;
      content: "";
      width: 2px;
      height: 0;
      background-color: #fff;
      margin: 3px auto 0 auto;
      position: relative;
      left: -2px;
      animation: scroll_line 1s linear infinite; }

@keyframes scroll_line {
  0% {
    height: 0; }
  100% {
    height: 97px; } }

.in_item_txt h2 {
  font-family: "FuturaLT";
  font-size: 7.1875rem;
  font-size: 12.25vmin;
  line-height: 1.12;
  letter-spacing: 5.8px;
  color: #4759d9; }

.in_item_txt h3 {
  font-size: 2.1875rem;
  font-size: 3.75vmin;
  font-weight: 300;
  line-height: 1.76;
  letter-spacing: 3.5px; }

.in_item_img {
  padding: 6% 0 4%;
  width: 64%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index: 1; }
  .in_item_img .item_photo:nth-child(1) {
    width: 47.897%;
    max-width: 560px;
    margin-right: 1.625rem;
    margin-bottom: 1.85rem; }
  .in_item_img .item_photo:nth-child(2) {
    width: 45.502%;
    max-width: 532px;
    margin-bottom: 1.85rem; }
  .in_item_img .item_photo:nth-child(3) {
    width: 47.897%;
    max-width: 560px;
    margin-right: 1.625rem; }
  .in_item_img .item_photo:nth-child(4) {
    width: 35.666%;
    max-width: 417px; }
  .in_item_img img {
    display: block;
    width: 100%; }

.index_item2 {
  padding-top: 3.4375rem;
  display: flex;
  flex-wrap: wrap; }
  .index_item2 a {
    position: relative;
    width: 100%;
    height: 100%;
    display: block; }
    .index_item2 a:after, .index_item2 a:before {
      position: absolute;
      display: block;
      width: 100%; }
    .index_item2 a:before {
      transition: .4s;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1; }
    .index_item2 a:after {
      content: '';
      height: 0;
      padding-bottom: 26%;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.89) 34%, rgba(255, 255, 255, 0)); }
    .index_item2 a:hover a {
      text-decoration: none; }
    .index_item2 a:hover:before {
      opacity: 0; }
  .index_item2 .title_box a {
    width: auto;
    height: auto; }
    .index_item2 .title_box a:before, .index_item2 .title_box a:after {
      display: none; }
  .index_item2 .in_work_first_img, .index_item2 .in_work_item_img {
    display: flex;
    position: relative;
    width: 50%; }
    .index_item2 .in_work_first_img .word_txt, .index_item2 .in_work_item_img .word_txt {
      width: 93%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2; }
      .index_item2 .in_work_first_img .word_txt h2, .index_item2 .in_work_item_img .word_txt h2 {
        font-size: 1.125rem;
        height: 3em;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 第幾行要顯示點點點 */
        -webkit-box-orient: vertical;
        color: #ffffff;
        margin-bottom: 1.125rem; }
  .index_item2 .in_work_first_img .word_photo {
    width: 100%;
    height: 0;
    padding-bottom: 84.709%;
    width: 100%; }
  .index_item2 .in_work_item_img {
    flex-wrap: wrap; }
    .index_item2 .in_work_item_img .in_work_item_box {
      width: 50%; }
    .index_item2 .in_work_item_img .word_photo {
      width: 100%;
      height: 100%; }

.index_item3 .title_box, .index_item2 .title_box {
  width: 60%;
  position: relative;
  margin: 0 auto 7.5rem;
  z-index: 2; }
  .index_item3 .title_box a, .index_item2 .title_box a {
    transition: .4s;
    position: absolute;
    color: #ffffff;
    font-size: 0.90625rem;
    display: inline-block;
    right: 0;
    bottom: 0px; }
    .index_item3 .title_box a i, .index_item2 .title_box a i {
      transition: .4s;
      position: absolute;
      right: -46px;
      bottom: 5px;
      width: 39px;
      height: 1px;
      background: #ffffff; }
      .index_item3 .title_box a i:after, .index_item2 .title_box a i:after {
        transition: .4s;
        position: absolute;
        left: 31px;
        top: -3px;
        content: "";
        display: inline-block;
        width: 10px;
        height: 1px;
        background: #ffffff;
        transform: rotate(42deg); }
    .index_item3 .title_box a:hover, .index_item2 .title_box a:hover {
      color: #4759d9;
      text-decoration: none; }
      .index_item3 .title_box a:hover i, .index_item2 .title_box a:hover i {
        background: #4759d9;
        right: -76px;
        width: 68px; }
        .index_item3 .title_box a:hover i:after, .index_item2 .title_box a:hover i:after {
          left: 60px;
          background: #4759d9; }

.article_list {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: center; }
  .article_list .sliderfor {
    width: 100%;
    min-height: 410px;
    margin: 0 auto 100px;
    position: relative; }
  .article_list li {
    list-style: none;
    position: relative; }
    .article_list li:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }
    .article_list li a {
      display: flex;
      align-items: center;
      position: relative;
      width: 410px;
      height: 410px;
      border-radius: 99em;
      overflow: hidden;
      position: relative;
      transform: scale(0.71);
      margin: auto;
      cursor: auto; }
      .article_list li a h2 {
        transform: scale(1.3); }
      .article_list li a:before {
        position: absolute;
        display: block;
        content: '';
        transition-property: all;
        transition-duration: 0.6s;
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
        width: 80%;
        height: 80%;
        background: rgba(0, 0, 0, 0.85);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1; }
      .article_list li a:hover {
        text-decoration: none; }
  .article_list .article_img {
    width: 100%; }
  .article_list .article_photo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0; }
  .article_list .article_content {
    transition: .4s;
    width: 58%;
    margin: auto;
    position: relative;
    z-index: 1;
    color: #ffffff; }
    .article_list .article_content h2 {
      transition: .35s;
      transition-timing-function: ease;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1.125rem;
      height: 3em;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical;
      position: relative;
      top: 0;
      width: 60%;
      margin: auto; }
    .article_list .article_content p {
      font-size: 0.9375rem;
      height: 7.5em;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical;
      display: block;
      width: 300px;
      position: absolute;
      top: 56%;
      left: 50%;
      opacity: 0;
      transform: translate(-50%, -50%); }
    .article_list .article_content .view_btn {
      margin-top: 50px;
      text-align: center;
      color: #4759d9;
      font-size: 0.9375rem;
      position: absolute;
      left: 50%;
      bottom: -110px;
      transform: translateX(-50%);
      opacity: 0; }
      .article_list .article_content .view_btn:after {
        display: block;
        content: '';
        width: 35%;
        height: 1px;
        background: #4759d9;
        margin: 5px auto 0 auto; }
  .article_list .slick-current.slick-center:after {
    display: none; }
  .article_list .slick-current.slick-center a {
    transition-delay: 0.8s;
    -webkit-transition-delay: 0.8s;
    /* Safari 和 Chrome */
    transform: scale(1);
    cursor: pointer; }
    .article_list .slick-current.slick-center a p {
      transition-duration: 0s; }
    .article_list .slick-current.slick-center a:hover {
      text-decoration: none;
      -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg=="); }
      .article_list .slick-current.slick-center a:hover:before {
        width: 100%;
        height: 100%; }
      .article_list .slick-current.slick-center a:hover h2 {
        top: -88px; }
      .article_list .slick-current.slick-center a:hover p {
        transition-duration: 0.4s;
        transition-timing-function: ease;
        transition-delay: .2s;
        opacity: 1; }
      .article_list .slick-current.slick-center a:hover .article_content {
        width: 80%; }
        .article_list .slick-current.slick-center a:hover .article_content .view_btn {
          opacity: 1; }
      .article_list .slick-current.slick-center a:hover .article_photo {
        animation-play-state: paused; }
  .article_list .slick-current.slick-center .article_photo {
    animation-play-state: running;
    animation: spin 13.3s linear .8s infinite; }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .article_list .slick-dots li {
    width: auto;
    margin: auto; }
  .article_list .slider-nav {
    display: inline-block !important; }
    .article_list .slider-nav li {
      text-indent: -10000px;
      width: auto !important;
      height: auto;
      float: none;
      display: inline-block;
      margin: auto; }
    .article_list .slider-nav .slick-dots {
      position: relative;
      margin: 0; }
    .article_list .slider-nav .slick-track {
      width: auto !important; }
    .article_list .slider-nav .slick-dots li button:before {
      background: #333333; }
    .article_list .slider-nav .slick-dots li.slick-active button:before {
      background: #ffffff; }
    .article_list .slider-nav .slick-next, .article_list .slider-nav .slick-prev {
      top: 64%;
      width: 32px;
      height: 32px;
      transition: 0.4s;
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em;
      background: #2b2c2e;
      font-size: initial;
      outline: 0; }
    .article_list .slider-nav .slick-prev {
      left: -54px; }
      .article_list .slider-nav .slick-prev:before {
        position: absolute;
        top: 28%;
        left: 50%;
        transform: translate(-50%);
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 7px 7px 0;
        border-color: transparent #ffffff transparent transparent; }
      .article_list .slider-nav .slick-prev:hover {
        background: #2f3a88; }
    .article_list .slider-nav .slick-next {
      right: -45px; }
      .article_list .slider-nav .slick-next:before {
        position: absolute;
        top: 28%;
        left: 54%;
        transform: translate(-50%);
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 0 7px 7px;
        border-color: transparent transparent transparent #ffffff; }
      .article_list .slider-nav .slick-next:hover {
        background: #2f3a88; }

.index_item3 {
  padding: 85px 0 78px 0;
  min-height: 739px; }

.index_item4 {
  padding: 56px 0 70px;
  background: url(../images/in_contact_bg.png?73f8f8a2) no-repeat top/cover; }
  .index_item4 article {
    background: none; }
  .index_item4 .form_box {
    padding: 0;
    width: 100%; }
  .index_item4 .title_box {
    margin-bottom: 1.25rem; }

@media screen and (max-width: 1366px) {
  .article_list li a {
    width: 390px;
    height: 390px;
    transform: scale(0.69); } }
  @media screen and (max-width: 1366px) and (max-width: 1024px) {
    .article_list li a {
      width: 370px;
      height: 370px;
      transform: scale(0.6); } }

@media screen and (max-width: 1024px) {
  .index_item3 {
    padding: 40px 0 50px;
    min-height: auto; }
  .index_item3 .title_box, .index_item2 .title_box {
    margin-bottom: 3.5rem; }
  .article_list .sliderfor {
    margin: 0 auto 20px; }
  .in_item_txt h2 {
    font-size: 10vmin; }
  .in_item_img {
    width: 58%;
    padding: 0; } }

@media (max-width: 767.99px) {
  .article_list .slick-current.slick-center a:hover:before {
    width: 80%;
    height: 80%; }
  .article_list .slick-current.slick-center a:hover h2 {
    top: 0; }
  .index_item1 .in_box {
    flex-wrap: wrap; }
  .in_item_txt {
    width: 81%;
    margin: 0 auto 40px; }
  .in_item_img, .index_item2 .in_work_first_img, .index_item2 .in_work_item_img {
    width: 100%; }
  .index_item2 {
    padding-top: 2.5rem;
    width: 90%;
    margin: auto; }
  .index_item2 .in_work_item_img .word_photo {
    width: 100%;
    height: 0;
    padding-bottom: 85%; }
  .index_item4 .form_box {
    width: 100%; } }

@media (max-width: 575.99px) {
  .index_item1 .sroll_btn {
    bottom: -24px; }
  @keyframes scroll_line {
    0% {
      height: 0; }
    100% {
      height: 67px; } }
  .index_item1 {
    min-height: auto !important;
    padding: 10% 0 13% 0; }
    .index_item1 .video_line {
      display: none; }
    .index_item1 .in_box {
      padding-left: 3%; }
  .in_item_txt h2 {
    font-size: 15vmin; }
  .in_item_txt h3 {
    font-size: 5.75vmin; }
  .index_item2 .in_work_first_img .word_txt h2, .index_item2 .in_work_item_img .word_txt h2 {
    font-size: 0.9375rem; }
  .indexPage main[role="main"] {
    padding-top: 70px; }
  .in_item_img .item_photo:first-child {
    margin-right: 0.7rem;
    margin-bottom: 0.7rem; }
  .in_item_img .item_photo:nth-child(2) {
    margin-bottom: 0.7rem; }
  .in_item_img .item_photo:nth-child(3) {
    margin-right: 0.7rem; }
  .article_list li {
    width: 270px;
    height: 270px; } }
  @media screen and (max-width: 575.99px) and (max-width: 375px) {
    .article_list li {
      width: 260px;
      height: 260px; } }
  @media screen and (max-width: 575.99px) and (max-width: 360px) {
    .article_list li {
      width: 250px;
      height: 250px; } }

@media (max-width: 575.99px) {
    .article_list li a {
      width: 100%;
      height: 100%;
      transform: scale(0.65); }
  .article_list h2 {
    transform: scale(1) !important;
    width: 85% !important;
    font-size: 1.125rem; }
  .article_list .sliderfor {
    left: 0; }
  .article_list .sliderfor {
    min-height: auto;
    margin-bottom: 38px; }
  .article_list .article_content {
    width: 73%; }
  .article_list .slick-current.slick-center a p, .article_list .slick-current.slick-center a .view_btn {
    display: none; }
  .index_item2 .title_box, .index_item3 .title_box {
    width: 88%;
    margin-bottom: 2.5rem; }
    .index_item2 .title_box a, .index_item3 .title_box a {
      bottom: 26px;
      font-size: 0.75rem; }
      .index_item2 .title_box a i, .index_item3 .title_box a i {
        position: relative;
        display: block;
        right: -42px;
        bottom: -9px;
        width: 21px; }
        .index_item2 .title_box a i:after, .index_item3 .title_box a i:after {
          left: 16px;
          top: -2px;
          width: 7px; }
      .index_item2 .title_box a:hover, .index_item3 .title_box a:hover {
        color: #ffffff; }
        .index_item2 .title_box a:hover i, .index_item3 .title_box a:hover i {
          background: #ffffff;
          position: relative;
          display: block;
          right: -42px;
          bottom: -9px;
          width: 21px; }
          .index_item2 .title_box a:hover i:after, .index_item3 .title_box a:hover i:after {
            left: 16px;
            top: -2px;
            width: 7px; } }

.profilePage .title_box {
  text-align: left;
  border-left: 4px solid #2f3a88;
  padding-left: 1.1875rem;
  line-height: initial;
  margin-bottom: 2.75rem; }
  .profilePage .title_box h1 {
    line-height: 1.8125rem;
    margin-bottom: 0.85rem; }

.profile_box {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5.6875rem; }

.pro_img {
  position: absolute;
  left: 0;
  width: 26%;
  max-width: 305px;
  -webkit-box-shadow: 12px 12px 0 #2f3a88;
  -moz-box-shadow: 12px 12px 0 #2f3a88;
  box-shadow: 12px 12px 0 #2f3a88;
  z-index: 1; }
  .pro_img img {
    width: 100%;
    max-width: 100%;
    height: auto !important; }

.pro_content {
  position: relative;
  top: 16px;
  width: 78%;
  max-width: 936px;
  border: solid 2px #a0a0a0;
  z-index: 0;
  padding: 2.25rem 1.6875rem 2.125rem 6.4375rem; }

@media (max-width: 767.99px) {
  .pro_img {
    width: 32%; } }

@media (max-width: 575.99px) {
  .profilePage .title_box {
    margin-bottom: 0.6rem;
    padding-left: 1.02rem; }
    .profilePage .title_box h1 {
      margin-bottom: .45rem; }
  .profile_box {
    width: 100%;
    display: block;
    margin-bottom: 3.75rem; }
  .pro_content {
    width: 100%;
    padding: 5rem 1rem 2.125rem 1rem; }
  .pro_img {
    position: relative;
    width: 70%;
    top: 50px;
    left: 50%;
    transform: translateX(-50%); } }

.wordPage .container-fluid {
  margin-bottom: 4.125rem; }

.wordPage .col-lg-4 {
  padding: 0; }

.tag_content {
  font-size: 1.125rem;
  letter-spacing: 1.8px;
  color: #ffffff;
  margin-bottom: 2.1125rem; }

.word_img {
  position: relative;
  width: 100%;
  min-width: 400px;
  height: 0;
  padding-bottom: 85.004%; }
  @media screen and (max-width: 1436px) {
    .word_img {
      min-width: 100%; } }
  .word_img:hover a {
    text-decoration: none; }
  .word_img:hover:before {
    opacity: 0; }
  .word_img:hover .word_photo {
    opacity: 1; }
  .word_img .word_photo, .word_img:after, .word_img:before {
    position: absolute;
    display: block;
    width: 100%; }
  .word_img .word_photo {
    transition: .4s;
    top: 0;
    left: 0;
    height: 100%;
    opacity: .6; }
  .word_img .word_txt {
    width: 80%;
    position: absolute;
    bottom: 1.0625rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
    .word_img .word_txt h2 {
      font-size: 1.125rem;
      height: 3em;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical;
      color: #ffffff;
      margin-bottom: 1.125rem; }
  .word_img:after {
    content: '';
    height: 0;
    padding-bottom: 26%;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.89) 34%, rgba(255, 255, 255, 0)); }

@media (max-width: 767.99px) {
  .word_img .word_txt {
    width: 92%; } }

@media (max-width: 575.99px) {
  .tag_content {
    font-size: 1rem;
    margin-bottom: 1.25rem; }
  .word_img .word_txt {
    bottom: 1.5625rem; } }

.article_detailPage .editor_Content header h2 {
  width: 100%; }

.articlePage main[role=main] {
  padding-top: 175px;
  margin-top: 0; }

.articlePage .word_img .word_photo {
  opacity: 1; }

.articlePage .word_img::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.85);
  width: 80%;
  height: 80%;
  z-index: 1; }

.nav-morph_1, .nav-morph_2, .nav-morph_3, .nav-morph_4 {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%); }

.bg_line {
  position: absolute;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  display: flex;
  justify-content: space-between; }
  .bg_line .bg_line_box {
    position: relative;
    width: 25%;
    max-width: 279px;
    height: 100%; }
    .bg_line .bg_line_box svg path {
      stroke: #35383d;
      stroke-width: 7px; }
    .bg_line .bg_line_box svg {
      position: absolute;
      content: '';
      display: block;
      height: 100%;
      left: 50%;
      transform: translateX(-50%); }

.articlePage .container-fluid {
  padding: 0; }

.articlePage .col-lg-3 {
  margin-bottom: 61.3px; }

.articlePage .word_img {
  min-width: 279px;
  min-height: 279px;
  padding-bottom: 100%;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  overflow: hidden;
  -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg=="); }
  .articlePage .word_img:before {
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    width: 80%;
    height: 80%;
    background: rgba(0, 0, 0, 0.85);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .articlePage .word_img:after {
    display: none; }
  .articlePage .word_img .word_txt {
    width: 51%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: initial; }
  .articlePage .word_img:hover .word_photo {
    opacity: .6; }
  .articlePage .word_img:hover:before {
    opacity: 1;
    width: 120%;
    height: 150%; }

@media (max-width: 575.99px) {
  .articlePage article {
    width: 75%; }
  .bg_line .bg_line_box:first-child, .bg_line .bg_line_box:last-child {
    display: none; }
  .bg_line .bg_line_box {
    width: 50%; }
  .articlePage .word_img:hover::before {
    width: 80%;
    height: 80%; }
  .articlePage main[role=main] {
    padding-top: 105px; }
  .word_img .word_txt h2 {
    font-size: 1rem; } }

.contactPage article {
  width: 100%;
  max-width: 100%;
  background: url(~images/contact_bg.png) no-repeat top center/cover; }

.contactPage main[role=main] {
  padding-bottom: 0; }

.contact_txt {
  text-align: center;
  width: 80%;
  margin: auto;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.7px;
  margin-bottom: 42px; }

.form_box {
  padding: 0 0 50px 0;
  margin: auto;
  width: 82%;
  max-width: 1200px;
  font-size: 1.0625rem; }
  .form_box .in_name h4 {
    padding-left: 13px; }
  .form_box h4 {
    letter-spacing: 1.7px;
    margin-right: 12px;
    position: relative;
    top: 20px;
    width: 93px; }
    .form_box h4 b {
      font-size: 1.0625rem;
      display: inline-block;
      margin-bottom: 0;
      margin-right: 3px; }
  .form_box b, .form_box .with-errors {
    display: block;
    font-size: 0.8125rem;
    color: #9b0107;
    margin-bottom: 1.25rem; }
  .form_box .with-errors {
    margin-top: 10px;
    margin-bottom: 0; }
  .form_box li {
    list-style: none; }
  .form_box .form-row {
    justify-content: space-between; }
  .form_box .col-lg-6 {
    max-width: 570px; }
  .form_box .col-12 {
    display: flex;
    min-height: 80px;
    margin-bottom: 20px;
    padding: 0; }
    .form_box .col-12 .form-group {
      width: -moz-calc(100% - 110px);
      width: -webkit-calc(100% - 110px);
      width: calc(100% - 110px); }
  .form_box .form-control {
    border: none;
    background: #434343;
    padding-left: 15px;
    padding-right: 15px;
    color: #fff; }
  .form_box .form-control:focus {
    box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0); }
  .form_box .form_btn {
    text-align: center;
    margin-bottom: 0; }
  .form_box .form_btn button, .form_box .form_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    border: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .form_box .form_btn button:hover, .form_box .form_btn a:hover {
      background: #4759d9;
      opacity: .5; }
  .form_box input[type='radio'] {
    display: none; }
  .form_box .rad_box {
    min-height: auto; }
    .form_box .rad_box h4 {
      top: 7px; }
  .form_box .rad_box .rad_content {
    display: flex;
    align-items: center; }
    .form_box .rad_box .rad_content .radio:nth-child(1) {
      margin-right: 30px; }

input[type='radio'] + label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 26px;
  padding: 0 5px 0 36px;
  outline: 0;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
  color: #ccc; }
  input[type='radio'] + label:before {
    height: 25px;
    width: 26px;
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    background: #434343;
    content: "";
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
  input[type='radio'] + label span {
    position: relative;
    display: block;
    top: 3px;
    width: 100%; }

input[type='radio']:checked + label:after {
  width: 10px;
  height: 10px;
  background: #fff;
  display: inline-block;
  position: absolute;
  top: 9px;
  left: 8px;
  content: "";
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em; }
  @media screen and (max-width: 800px) {
    input[type='radio']:checked + label:after {
      top: 9px; } }

@media (max-width: 1199.99px) {
  .contactPage .contact_txt {
    margin-bottom: 30px; }
  .form_box .col-lg-6 {
    max-width: 100%; }
  .form_box .col-12 {
    display: block;
    min-height: initial; }
    .form_box .col-12 .form-group {
      width: 100%; }
  .form_box input {
    height: 40px; }
  .form_box h4 {
    top: 0;
    margin-bottom: 1em; }
  .form_box .in_name h4 {
    padding-left: 0; }
  .form_box .rad_box h4 {
    top: 0; }
  .form_box .form_btn {
    margin-top: 20px; }
    .form_box .form_btn button {
      padding: 16.5px 40px; }
  .form_box .form-group {
    margin-bottom: 1.5rem; } }

@media (max-width: 575.99px) {
  .contact_txt {
    font-size: 0.9375rem; }
  .form_box {
    font-size: 1rem; } }

.newsPage .title_box h2 {
  position: relative; }
  .newsPage .title_box h2:before {
    width: 100%;
    height: 1px;
    position: absolute;
    content: '';
    background: linear-gradient(270deg, #35383D, #FFFFFF, #35383D);
    left: 0;
    top: -4px;
    animation: newslinerun 5s linear infinite;
    background-size: 300%; }

@keyframes newslinerun {
  0% {
    background-position: 300%; }
  100% {
    background-position: 0%; } }

.newsPage .newsitem {
  padding: 0; }

.newsPage .word_img {
  padding-bottom: 51%; }
  @media (max-width: 575.99px) {
    .newsPage .word_img {
      padding-bottom: 52%; } }

@media (max-width: 575.99px) {
  .newsPage .word_txt {
    bottom: 0; } }

.newsPage .word_txt h2 {
  margin-bottom: 0; }

.newsPage .word_txt div {
  margin-bottom: 10px; }

.new-con {
  padding: 0 4%;
  position: relative; }
  .new-con .word_img {
    padding-bottom: 51%;
    background: #000000; }
  .new-con .word_txt h2 {
    margin-bottom: 0; }
  .new-con .word_txt div {
    margin-bottom: 10px;
    color: #FFFFFF; }
  .new-con .news-l {
    padding-left: 20px;
    padding-right: 20px; }
  .new-con .news-r {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 100px; }
    @media (max-width: 768px) {
      .new-con .news-r {
        transform: translateX(21%);
        margin-top: 75px; } }
    @media (max-width: 557px) {
      .new-con .news-r {
        margin-top: 40px; } }

.news-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 78vw;
  padding-bottom: 28%; }
  @media (max-width: 768px) {
    .news-line {
      left: 49%;
      display: none; } }

.nline-mo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 82vw;
  height: 84vw;
  opacity: 0; }
  @media (max-width: 769px) {
    .nline-mo {
      opacity: 1;
      display: block; } }
