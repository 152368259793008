footer{
    width: 100%;
    position: relative;
    padding: 36px 80px 30px 80px;
    background: $black;
    color: $white;
    line-height: 1.5;
    a{
        color: $white;
        text-decoration: none;
        &:hover{
            color:$blue;
        }
    }
}
.footer_box{
    position: relative;
    display: flex;
    align-items: flex-end;
}



.f_email{
    font-size: pxToEm(15);
    letter-spacing: 0.8px;
    margin-left: 2.9rem;
}

.copyright{
    position: absolute;
    right: 0;
    font-size: pxToEm(13);
    letter-spacing: 0.7px;
}

@include media-breakpoint-down(lg){
    footer{
        padding: 30px 25px ;
    }
    .footer_box{
        display: block;

    }
    .f_email{
        margin-left: 0;
        margin: 23px 0;
    }
    .copyright{
        position: relative;
    }
}